import React from 'react';
import img from './../../public/images/lef_image.png';
import { useNavigate } from 'react-router-dom';
import Subtitle from './Subtitle';
import Title from './Title';
import Button from '../../utilities/Button';

const NotreMission = () => {
  const navigate = useNavigate();
  const clickHandler = () => navigate('/mission');

  return (
    <div className=' py-9'>
      <div className=' w-[95%] xl:w-[80%] gap-16 mx-auto grid grid-cols-1 lg:grid-cols-2'>
        <div className=' rounded-tl-[2rem] bg-[#00075e] '>
          <img
            src={img}
            alt='image'
            className=' border-[4px] rounded-tl-[2rem] rounded-br-[2rem] border-[#00075e] md:translate-x-6 translate-y-6 w-full h-[450px] md:h-[550px] object-cover'
          />
        </div>
        <div>
          <Title text='Clinical Trial Excellence' />
          <Subtitle
            spanText='Clinical Trials'
            subtile='Leading CRO ensuring precision in '
          />
          <p className=' text-justify'>
            Keindeya is a leading Contract Research Organization (CRO) that
            offers comprehensive support to its clients in the pharmaceutical,
            biotechnology, and institutional sectors for the management of their
            clinical trials across Europe and Africa. Our goal is to provide
            tailored expertise and consultancy services ranging from Phase I to
            IV in various areas of clinical research. At Keindeya, we understand
            the importance of having well-trained monitors who are proficient in
            Good Clinical Practice (GCP), the specific therapeutic area of
            study, and the details outlined in your study protocol. This ensures
            that our monitoring team is equipped to effectively oversee and
            manage the progress of your clinical trials with precision and
            accuracy. Moreover, we are committed to upholding strict adherence
            to ethical, scientific, and medical standards throughout the
            duration of your clinical trial. This commitment serves as a guiding
            principle for both Keindeya and our partners, ensuring the
            credibility and integrity of the research conducted under our
            supervision. By choosing Keindeya as your trusted CRO partner, you
            can rest assured that your clinical trials will be conducted with
            the highest level of professionalism and excellence, ultimately
            leading to successful outcomes and advancements in the field of
            healthcare and pharmaceuticals.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotreMission;
