import Approch from '../pages/Approch';
import BlogDetails from '../pages/BlogDetails';
import Career from '../pages/Career';
import Contact from '../pages/Contact';
import Feasability from '../pages/Feasability';
import Home from '../pages/Home';
import Monitoring from '../pages/Monitoring';
import News from '../pages/News';
import NotFound from '../pages/NotFound';
import NotreMission from '../pages/NotreMission';
import NotreVision from '../pages/NotreVision';
import Publication from '../pages/Publication';
import PublicationsDetails from '../pages/PublicationsDetails';
import QualityAssurance from '../pages/QualityAssurance';
import Selection from '../pages/Selection';

export function usePages() {
  const publicUrl = [
    { path: '/', Component: Home, exact: true },
    { path: '/news', Component: News, exact: true },
    { path: '/vision', Component: NotreVision },
    { path: '/mission', Component: NotreMission },
    { path: '/approch', Component: Approch },
    { path: '/publication', Component: Publication },
    { path: '/feasability', Component: Feasability },
    { path: '/selection', Component: Selection },
    { path: '/monitoring', Component: Monitoring },
    { path: '/quality-assurance', Component: QualityAssurance },
    { path: '/career', Component: Career },
    { path: '/contact', Component: Contact },
    { path: '/blog/:id', Component: BlogDetails },
    { path: '/publications/:id', Component: PublicationsDetails },
    { path: '*', Component: NotFound },
  ];

  return { publicUrl };
}
