import img from '../public/images/banner_9.png';

const FooterContact = () => {
  return (
    <section
      style={{
        background: `linear-gradient(rgba(0, 16, 96, 0.70), rgba(0, 16, 96, 0.70)),url(${img})`,
        // height: '30vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        color: '#FFFFFF',
      }}
      className=' py-10 '
    >
      <div className=' flex flex-col md:grid-cols-2 w-[95%] lg:w-[90%] xl:w-[80%] 2xl:w-[60%] mx-auto'>
        <h2 className=' font-semibold text-[1.4rem] text-center  md:text-[1.8rem]'>
          More information about
        </h2>
        <div className=' p-2 md:p-7 items-start space-y-8 md:space-y-0 flex-col md:flex-row flex w-full justify-between '>
          <div className=' rounded-md inline-block  '>
            <div className='  flex-col flex items-start  '>
              <div className=' space-y-1 flex flex-col items-start '>
                <span className=' uppercase'>Headquater:</span>
                <div className=' flex  flex-col space-y-2'>
                  <div className=' space-x-2 flex items-start'>
                    <span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='size-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75'
                        />
                      </svg>
                    </span>
                    <div className=' space-x-3 flex'>
                      <span className=' '>Email:</span>
                      <span> sales@keindeya.com</span>
                    </div>
                  </div>
                  <div className=' space-x-2 flex items-start'>
                    <span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        fill='currentColor'
                        className='size-6'
                      >
                        <path
                          fillRule='evenodd'
                          d='m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z'
                          clipRule='evenodd'
                        />
                      </svg>
                    </span>
                    <div className=' space-x-3 flex'>
                      <span className=' '>Adresse:</span>
                      <span>
                        Kissosso Quartier plateau
                        <br />
                        Commune de Matoto
                        <br />
                        CONAKRY - Guinée
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=' md:border-l-[2px] md:pl-5  md:border-[#fff] space-y-4 flex flex-col'>
            <span className=' uppercase'>Europe:</span>
            <div className=' flex  flex-col space-y-2'>
              <div className=' space-x-2 flex items-start'>
                <span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='size-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75'
                    />
                  </svg>
                </span>
                <div className=' space-x-3 flex'>
                  <span className=' '>Email:</span>
                  <span> sales@keindeya.com</span>
                </div>
              </div>
              <div className=' space-x-2 flex items-start'>
                <span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    fill='currentColor'
                    className='size-6'
                  >
                    <path
                      fillRule='evenodd'
                      d='m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z'
                      clipRule='evenodd'
                    />
                  </svg>
                </span>
                <div className=' space-x-3 flex'>
                  <span className=' '>Adresse:</span>
                  <span>
                    32, avenue du Général de Gaulle
                    <br /> 77220 TOURNAN EN BRIE - France
                  </span>
                </div>
              </div>
            </div>
          </div>{' '}
        </div>
      </div>
    </section>
  );
};

export default FooterContact;
