import React, { useEffect, useState } from 'react';
import img_1 from './../../public/images/slider_1.png';
import img_2 from './../../public/images/slider_6.png';
import img_3 from './../../public/images/slider_5.png';
import { useNavigate } from 'react-router-dom';

const carousels = [{ img: img_1 }, { img: img_2 }, { img: img_3 }];

const HeaderCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const navigate = useNavigate();

  const nextSlide = () => {
    setIsAnimating(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carousels.length);
    setIsAnimating(false);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 6000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className=' h-[40vh] md:h-[60vh] lg:h-[70vh] relative overflow-hidden carousel'>
      <div className='carousel__text absolute z-10 top-0 bottom-0 right-0 md:right-[55%] left-0 '>
        <div className=' flex flex-col justify-center md:justify-start md:block absolute top-[30%]  md:left-[10%] font-extrabold uppercase'>
          <h1 className=' hidden md:block leading-[1.2] text-[2rem] md:text-[3rem] lg:text-[4rem] text-[#00075e] '>
            Santé et Bien-être,
            <br /> Notre Mission
          </h1>
          <h1 className=' text-center md:hidden leading-[1.2] text-[1.2rem] md:text-[4rem] text-[#ffffff] '>
            Santé et Bien-être, Notre Mission
          </h1>
          <div className=' flex items-center justify-center md:block mt-2'>
            <button
              onClick={() => navigate('/mission')}
              className=' text-[0.8rem] md:text-[1rem] hover:bg-transparent transition-all hover:border-[#00075e] hover:text-[#00075e] border-[1px] rounded-[1rem] bg-[#00075e]  px-8 py-3 text-white'
            >
              Savoir plus
            </button>
          </div>
        </div>
      </div>
      <div className='h-full w-full flex'>
        {carousels.map((carousel, index) => (
          <img
            key={index}
            src={carousel.img}
            alt={`Slide ${index + 1}`}
            className={`absolute transition-transform w-full  duration-800 ease-in-out ${
              index === currentIndex
                ? 'transform translate-x-0'
                : 'transform translate-x-full'
            }`}
          />
        ))}
      </div>

      <button
        onClick={nextSlide}
        className='absolute next__button right-[2rem] top-1/2 transform  rounded-full -translate-y-1/2 bg-transparent hover:bg-[#00075e]  border-[1px] border-[#00075e] hover:text-white p-2'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='size-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='m8.25 4.5 7.5 7.5-7.5 7.5'
          />
        </svg>
      </button>
    </div>
  );
};

export default HeaderCarousel;
